import { motion } from "framer-motion"
import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"
import Heading from "../components/Heading"
import Img from "gatsby-image"

import { gallery } from "../styles/facilityDetail.module.scss"

function facilityDetails({ data }) {
  const title = data.facilityDetailsJson.name
  const headerImg = data.facilityDetailsJson.banner.childImageSharp.fluid
  const thumb = data.facilityDetailsJson.thumb.childImageSharp.fluid
  const items = data.facilityDetailsJson.items
  const group = [...new Set(items.map(([v]) => v).sort())]
  const img = data.facilityDetailsJson.img

  return (
    <Layout>
      <Helmet>
        <title>
          {title} | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan
          Terencana
        </title>
        <meta
          name="description"
          content="Selain menyediakan hunian yang nyaman dan dibagi menjadi berbagai cluster, Grand Wisata Bekasi juga turut menghadirkan ruko untuk mendukung kegiatan bisnis penghuninya."
        />
        <meta
          name="keywords"
          content="grand wisata bekasi,grand wisata,sinar mas land,properti,real estate,rumah"
        />
        <meta name="distribution" content="web" />
        <meta
          property="og:title"
          content={`${title} | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan
          Terencana`}
        />
        <meta
          property="og:description"
          content="Grand Wisata mengerti bahwa di era modernisasi seperti saat ini, mobilitas adalah hal penting. Untuk itu, Grand Wisata hadir dilokasi strategis dan dengan akses yang baik untuk memudahkan mobilitas anda."
        />
        <meta property="og:image" content={thumb} />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <PageHeader bg={headerImg}>{title}</PageHeader>
        <section className="container">
          <div className="row">
            <div className="col-12">
              {group.map(g => {
                return (
                  <>
                    <Heading>{g}</Heading>
                    {items
                      .filter(item => item.startsWith(g))
                      .map(item => {
                        return (
                          <p>
                            {item}
                            {item === "Cafe Walk" ? (
                              <ul>
                                <li>Pizza hut</li>
                                <li>SHSD</li>
                                <li>Bebek Kaleyo</li>
                                <li>HOB</li>
                                <li>Pampalassa</li>
                                <li>Warung Leko</li>
                                <li>Abuba Steak</li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </p>
                        )
                      })}
                  </>
                )
              })}
            </div>
          </div>
          <div
            id="carouselExampleIndicators"
            class="carousel slide mt-5"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              {img.map((img, idx) => {
                return (
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={idx}
                    class={idx === 0 ? "active" : ""}
                    aria-current="true"
                    aria-label={`Slide ${idx}`}
                    key={idx}
                  ></button>
                )
              })}
            </div>
            <div class="carousel-inner">
              {img.map((img, idx) => {
                return (
                  <div
                    className={
                      idx === 0 ? "carousel-item active" : "carousel-item"
                    }
                    key={idx}
                  >
                    <Img
                      fluid={img.childImageSharp.fluid}
                      className={`${gallery} d-block w-100`}
                      alt="..."
                    />
                  </div>
                )
              })}
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
          {/* {img.length > 0 ? (
            <div className={`row g-0 mt-5 ${gallery}`}>
              {img.map(item => {
                return (
                  <Img
                    fluid={item.childImageSharp.fluid}
                    className={`col-6 ${img.length < 4 ? "col" : "col-sm-3"}`}
                  ></Img>
                )
              })}
            </div>
          ) : (
            ""
          )} */}
        </section>
        <PageFooter></PageFooter>
      </motion.div>
    </Layout>
  )
}

export const query = graphql`
  query facilityDetails($slug: String) {
    facilityDetailsJson(slug: { eq: $slug }) {
      banner {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thumb {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      items
      name
      slug
      img {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            src
          }
        }
      }
    }
  }
`

export default facilityDetails
