import React from "react"
import "./heading.scss"

function Heading({ children }) {
  return (
    <>
      <h2 className="section-heading">{children}</h2>
      <hr className="heading-line" />
    </>
  )
}

export default Heading
